import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import BarChartRoundedIcon from "@material-ui/icons/BarChartRounded";
import CachedIcon from "@material-ui/icons/Cached";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import ColorLensOutlinedIcon from "@material-ui/icons/ColorLensOutlined";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import EventAvailableOutlinedIcon from "@material-ui/icons/EventAvailableOutlined";
import LinkRoundedIcon from "@material-ui/icons/LinkRounded";
import RepeatRoundedIcon from "@material-ui/icons/RepeatRounded";
import TodayOutlinedIcon from "@material-ui/icons/TodayOutlined";
import AdjustIcon from "../../img/adjust-icon.svg";
import CoffeeIcon from "../../img/coffee.svg";
import CogIcon from "../../img/cog-icon.svg";
import GcalLogo from "../../img/gcal-outline.svg";
import GridIcon from "../../img/grid.svg";
import HashtagIcon from "../../img/hash.svg";
import Hourglass from "../../img/hourglass.svg";
import PuzzleIcon from "../../img/puzzle-icon.svg";
import SlackLogo from "../../img/slack-outline.svg";
import UserAddIcon from "../../img/user-add-icon.svg";
import UserGroup from "../../img/user-group-icon.svg";
import UsersIcon from "../../img/users-icon.svg";
import WavingHand from "../../img/waving-hand.svg";
import { QuestTypeStr } from "../../reclaim-api/Users";
import { YouTubeOptions } from "../../types/youtube";
import { QuestGenericPopover } from "./QuestGenericPopover";
import { QuestGroup, QuestGroupMap, QuestsConfig } from "./quests.types";

const SLACK_APP_ID = process.env.NEXT_PUBLIC_SLACK_APP_ID;

export const DEFAULT_VIDEO_PLAYER_OPTIONS: YouTubeOptions = {
  autoplay: 1,
};

export const QUESTS_DIRECTORY: { [QG in QuestGroup]: (QuestTypeStr & QuestGroupMap[QG])[] } = {
  GETTING_STARTED: [
    "WELCOME_TO_RECLAIM",
    "SYNC_CALENDAR",
    "SET_AVAILABILITY",
    "SET_SCHEDULING_HOURS",
    "INSTALL_GCAL_ADDON",
    "INSTALL_SLACK_INTEGRATION",
  ],
  HABITS: ["HABITS_OVERVIEW", "CREATE_AND_CUSTOMIZE_HABITS", "MANAGE_HABITS"],
  SMART_MEETINGS: [
    "SCHEDULING_LINKS_OVERVIEW",
    "SETUP_SCHEDULING_LINKS",
    "SETUP_TEAM_LINKS",
    "SMART_ONE_ON_ONE_OVERVIEW",
    "SETUP_FIRST_SMART_ONE_ON_ONE",
    "MANAGE_SMART_ONE_ON_ONE",
  ],
  TASKS: ["TASK_OVERVIEW", "ADD_FIRST_TASK", "ADD_TASK_INTEGRATION", "MANAGE_TASKS", "SET_TASK_DEFAULTS"],
  CUSTOMIZE_CALENDAR: [
    "AUTO_SCHEDULE_BREAK_AND_TRAVEL_TIME",
    "SETUP_NO_MEETING_DAYS",
    "CUSTOMIZE_EVENT_COLOR_CODING",
    "TRACK_AND_ANALYZE_PRODUCTIVITY",
  ],
  INVITE_TEAM: ["ADD_MEMBERS_TO_TEAM", "SELECT_PLAN"],
};

export const QUESTS_CONFIG: QuestsConfig = {
  /********************/
  /*  GETTING STARTED */
  /********************/
  GETTING_STARTED: {
    id: "GETTING_STARTED",
    title: "Get started",
    quests: [
      {
        id: "WELCOME_TO_RECLAIM",
        groupId: "GETTING_STARTED",
        title: "Welcome to Reclaim",
        description: "Watch a 2-minute video to learn about how Reclaim works.",
        shortDescription: "A quick video on core concepts",
        duration: "2min",
        icon: WavingHand,
        steps: [{ id: "WATCH_WELCOME_VIDEO", type: "video", videoLink: "hJyct6lLAM0" }],
      },
      {
        id: "SET_AVAILABILITY",
        groupId: "GETTING_STARTED",
        title: "Set your availability",
        description: "Set which calendars you'd like Reclaim to check availability from.",
        shortDescription: "Set availability from other calendars",
        duration: "4min",
        icon: CalendarTodayOutlinedIcon,
        steps: [
          { id: "GOTO_CONNECTED_CALENDARS", type: "redirect", redirect: "/settings/accounts" },
          {
            id: "ADD_NEW_ACCOUNT",
            type: "action",
            popoverConfig: {
              description: "You can add a calendar from another Google account by clicking here.",
            },
          },
          {
            id: "ADD_AVAILABILITY_CALENDARS",
            type: "action",
            popoverConfig: {
              description: 'Click "Edit" to select which calendars you want Reclaim to use for you availability.',
            },
          },
          {
            id: "SELECT_A_CALENDAR",
            type: "orb",
            popoverConfig: {
              description: "Select which calendar to sync below.",
            },
          },
          {
            id: "SAVE_CONNECTED_CALENDAR",
            type: "action",
            popoverConfig: {
              description: "Save to sync the calendar.",
            },
          },
        ],
      },
      {
        id: "SYNC_CALENDAR",
        groupId: "GETTING_STARTED",
        title: "Sync your calendars",
        description: "Automatically sync & copy events across calendars to prevent overbookings.",
        shortDescription: "Sync events across calendars",
        duration: "4min",
        indeterminant: true,
        completionNotification: true,
        icon: CachedIcon,
        steps: [
          { id: "GOTO_CALENDAR_SYNC", type: "redirect", redirect: "/sync" },
          {
            id: "CLICK_CREATE_NEW_SYNC",
            type: "action",
            popoverConfig: {
              description: "Let's get started by selecting the calendars you'd like to sync.",
            },
          },
          {
            id: "CLICK_NEXT_PREFERENCES",
            type: "action",
            popoverConfig: {
              description: "Now let's get all the settings configured the way you want them.",
            },
          },
          {
            id: "SYNC_PRIVACY",
            type: "orb",
            popoverConfig: {
              description: "Update what events Reclaim should sync, how they should appear, & more.",
              img: "/img/quests/quests-sync-policies.png",
            },
          },
          {
            id: "CLICK_SYNC_CREATION",
            type: "action",
            popoverConfig: {
              description: "Time to sync your events.",
            },
          },
        ],
      },
      {
        id: "SET_SCHEDULING_HOURS",
        groupId: "GETTING_STARTED",
        title: "Set your scheduling hours",
        description: "Define your personal, working & meeting hours to schedule around your preferences.",
        shortDescription: "Schedule around your hours",
        duration: "2min",
        icon: Hourglass,
        steps: [
          { id: "GOTO_HOURS_SETTINGS", type: "redirect", redirect: "/settings/hours" },
          {
            id: "MEETING_HOURS",
            component: QuestGenericPopover,
            type: "orb",
            popoverConfig: {
              description:
                "Update the hours you're available for work tasks & synced events, personal tasks, & meetings.",
              img: "/img/quests/quests-hour-types.png",
            },
          },
          {
            id: "HINT_HOURS_INPUT",
            component: QuestGenericPopover,
            type: "orb",
            popoverConfig: {
              description: "Toggle the days of the week & update the range of hours for each day.",
              img: "/img/quests/quests-hours-tip.png",
            },
          },
          {
            id: "HOURS_INPUT",
            type: "action",
            popoverConfig: {
              description: "Begin editing your hours.",
            },
          },
        ],
      },
      {
        id: "INSTALL_GCAL_ADDON",
        groupId: "GETTING_STARTED",
        title: "Install the Google Calendar Add-on",
        description: "Manage Reclaim from Google Calendar & share scheduling links from your Gmail inbox.",
        shortDescription: "Share scheduling links from Gmail",
        duration: "1min",
        icon: GcalLogo,
        indeterminant: true,
        steps: [
          {
            id: "GOTO_GOOGLE_ADDON",
            type: "url",
            url: "https://workspace.google.com/marketplace/app/reclaimai/950518663892",
          },
        ],
      },
      {
        id: "INSTALL_SLACK_INTEGRATION",
        groupId: "GETTING_STARTED",
        title: "Install the Slack integration",
        description: "Add Reclaim to your Slack workspace & auto-sync your Slack status to your schedule.",
        shortDescription: "Auto-sync your Slack status",
        duration: "1min",
        icon: SlackLogo,
        indeterminant: true,
        steps: [
          {
            id: "GOTO_SLACK_INTEGRATION_LISTING",
            type: "url",
            url: `https://slack.com/app_redirect?app=${SLACK_APP_ID}`,
          },
        ],
      },
    ],
  },
  /********************/
  /*      HABITS      */
  /********************/
  HABITS: {
    id: "HABITS",
    title: "Set your Habits",
    quests: [
      {
        id: "HABITS_OVERVIEW",
        groupId: "HABITS",
        title: "Habits overview",
        description: "Watch a 1-minute video on how to auto-schedule time for your recurring routines.",
        shortDescription: "A quick video on core habit concepts",
        duration: "1min",
        icon: RepeatRoundedIcon,
        steps: [{ id: "WATCH_HABITS_OVERVIEW_VIDEO", type: "video", videoLink: "fDEZmwcL6nI" }],
      },
      {
        id: "CREATE_AND_CUSTOMIZE_HABITS",
        groupId: "HABITS",
        title: "Create & customize your Habits",
        description: "Add, customize & prioritize your routines to keep your workweek focused & balanced.",
        shortDescription: "Add & customize your routines",
        duration: "4min",
        icon: AdjustIcon,
        steps: [
          { id: "GOTO_HABITS", type: "redirect", redirect: "/habits" },
          { id: "ACCESS_HABIT_TEMPLATES", type: "orb" },
          { id: "STACK_RANKING_HABITS", type: "orb" },
          { id: "CREATE_NEW_HABIT", type: "orb" }, // This component should trigger the create redirect on "Got it"
          { id: "HABIT_FORM_WALK_THROUGH", type: "orb" },
        ],
      },
      {
        id: "MANAGE_HABITS",
        groupId: "HABITS",
        title: "Create & customize your Habits",
        description: "Learn how to work with Habit events on your schedule during your week.",
        shortDescription: "How to work with Habits on your schedule",
        duration: "3min",
        icon: TodayOutlinedIcon,
        steps: [
          { id: "GOTO_PLANNER", type: "redirect", redirect: "/planner" },
          { id: "WATCH_HABIT_MANAGEMENT_VIDEO", type: "orb" }, // Watch video triggered from orb component
        ],
      },
    ],
  },
  /********************/
  /*  SMART MEETINGS  */
  /********************/
  SMART_MEETINGS: {
    id: "SMART_MEETINGS",
    title: "Set up your smart meetings",
    quests: [
      {
        id: "SCHEDULING_LINKS_OVERVIEW",
        groupId: "SMART_MEETINGS",
        title: "Scheduling Links overview",
        description:
          "Learn how to use Reclaim's scheduling links to share your availability & get the right meetings scheduled sooner.",
        shortDescription: "How to use Reclaim's scheduling links",
        duration: "2min",
        icon: LinkRoundedIcon,
        steps: [{ id: "WATCH_SCHEDULING_LINKS_VIDEO", type: "video", videoLink: "fDEZmwcL6nI" }],
      },
      {
        id: "SETUP_SCHEDULING_LINKS",
        groupId: "SMART_MEETINGS",
        title: "Set up your scheduling links",
        description: "Start creating & sending scheduling links, & learn how to manage your scheduling links & pages.",
        shortDescription: "Allow others to book time with you",
        duration: "5min",
        icon: DateRangeOutlinedIcon,
        steps: [
          { id: "GOTO_SCHEDULING_LINKS", type: "redirect", redirect: "/scheduling-links" },
          {
            id: "CLICK_SL_ONBOARDING",
            type: "action",
            popoverConfig: {
              description: "Start the Scheduling Links onboarding.",
            },
          },
          {
            id: "CLICK_DONE_SL_ONBOARDING",
            type: "action",
            popoverConfig: {
              description: "Finish the Scheduling Links onboarding.",
            },
          },
        ],
      },
      {
        id: "SETUP_TEAM_LINKS",
        groupId: "SMART_MEETINGS",
        title: "Set up team links",
        description:
          "Create your first scheduling link with multiple members of your team to share your combined availability.",
        shortDescription: "Send links based on your team's availability",
        duration: "2min",
        icon: UserGroup,
        steps: [
          { id: "GOTO_SCHEDULING_LINK_FORM", type: "redirect", redirect: "/scheduling-links/new/edit" },
          {
            id: "SL_FORM_INFO",
            type: "orb",
            popoverConfig: {
              description:
                "Here you can give assign your scheduling link to a group, set one or multiple durations, add your team members as other meeting organizers, and more.",
            },
          },
          {
            id: "CLICK_ADD_TEAM_MEMBERS",
            type: "orb",
            popoverConfig: {
              description:
                "Add team members to your scheduling link. If you have none, add them on the Billing and Team page.",
            },
          },
          {
            id: "CLICK_CREATE_LINK",
            type: "action",
            popoverConfig: {
              description: "Create your team link.",
            },
          },
        ],
      },
      {
        id: "SMART_ONE_ON_ONE_OVERVIEW",
        groupId: "SMART_MEETINGS",
        title: "Smart 1:1s overview",
        description: "Learn how Smart 1:1s automatically schedule & manage your recurring one-on-one meetings.",
        shortDescription: "Auto-schedule & reschedule your 1:1s",
        duration: "4min",
        icon: UsersIcon,
        steps: [{ id: "WATCH_SMART_11s_VIDEO", type: "video", videoLink: "fDEZmwcL6nI" }],
      },
      {
        id: "SETUP_FIRST_SMART_ONE_ON_ONE",
        groupId: "SMART_MEETINGS",
        title: "Set up your first Smart 1:1",
        description: "Start converting your existing one-on-one meetings to Smart 1:1s.",
        shortDescription: "Convert existing 1:1 meetings",
        duration: "4min",
        icon: EventAvailableOutlinedIcon,
        steps: [
          { id: "GOTO_SMART_11", type: "redirect", redirect: "/one-on-ones" },
          {
            id: "SMART_11_PAGE_INFO",
            type: "orb",
            popoverConfig: {
              description:
                "You can create a new Smart 1:1 or convert your existing 1:1s we detect into Reclaim Smart 1:1s.",
            },
          },
          {
            id: "CLICK_NEW_SMART_11",
            type: "action",
            popoverConfig: {
              description: "Create your first Smart 1:1.",
            },
          },
          {
            id: "SMART_11_FORM_INFO",
            type: "orb",
            popoverConfig: {
              description:
                "Enter the email of the invitee, set the duration and frequency of the recurring meeting, and add a location or videoconferencing link.",
            },
          },
          {
            id: "CREATE_SMART_11",
            type: "action",
            popoverConfig: {
              description: "Create your Smart 1:1.",
            },
          },
        ],
      },
      {
        id: "MANAGE_SMART_ONE_ON_ONE",
        groupId: "SMART_MEETINGS",
        title: "Manage Smart 1:1s on your calendar",
        description: "Learn how to manage your Smart 1:1s on your calendar during the week.",
        shortDescription: "How to manage your 1:1 meetings",
        duration: "2min",
        icon: TodayOutlinedIcon,
        steps: [
          { id: "GOTO_PLANNER", type: "redirect", redirect: "/planner" },
          {
            id: "MANAGE_SMART_11_VIDEO",
            type: "orb",
            popoverConfig: {
              description: "Click below to watch a video on managing your Smart 1:1s.",
            },
            videoLink: "fDEZmwcL6nI",
          },
        ],
      },
    ],
  },
  /********************/
  /*      TASKS       */
  /********************/
  TASKS: {
    id: "TASKS",
    title: "Make time for your tasks",
    quests: [
      {
        id: "TASK_OVERVIEW",
        groupId: "TASKS",
        title: "Tasks overview",
        description: "Learn how to use Tasks to automatically block flexible time for your to-dos.",
        shortDescription: "Block flexible time for your to-dos",
        duration: "2min",
        icon: AssignmentTurnedInOutlinedIcon,
        steps: [{ id: "WATCH_TASKS_VIDEO", type: "video", videoLink: "fDEZmwcL6nI" }],
      },
      {
        id: "ADD_FIRST_TASK",
        groupId: "TASKS",
        title: "Add your first Task",
        description: "Create your first task & start blocking time to get stuff done.",
        shortDescription: "Start blocking time to get stuff done",
        duration: "2min",
        icon: TodayOutlinedIcon,
        steps: [
          { id: "GOTO_TASKS", type: "redirect", redirect: "/tasks" },
          {
            id: "CLICK_NEW_TASK",
            type: "action",
            popoverConfig: {
              description: "Click to add a new task.",
            },
          },
          {
            id: "TASK_FORM_INFO",
            type: "orb",
            popoverConfig: {
              description: "Name your task, add the amount of time needed, and specify the times to do the task.",
            },
          },
          {
            id: "SAVE_TASK",
            type: "action",
            popoverConfig: {
              description: "Create your task.",
            },
          },
        ],
      },
      {
        id: "ADD_TASK_INTEGRATION",
        groupId: "TASKS",
        title: "Add task integrations",
        description: "Integrate Reclaim with your project management tools to automatically sync your tasks.",
        shortDescription: "Auto-sync your tasks from other apps",
        duration: "4min",
        icon: PuzzleIcon,
        steps: [
          { id: "GOTO_INTEGRATIONS", type: "redirect", redirect: "/settings/integrations" },
          {
            id: "TASKS_INTEGRATIONS_INFO",
            type: "orb",
            popoverConfig: {
              description:
                "Integrating with one or more of your project management tools will sync your tasks with Reclaim. You may need to upgrade your account to Pro or Team to integrate with some of these tools.",
            },
          },
        ],
      },
      {
        id: "MANAGE_TASKS",
        groupId: "TASKS",
        title: "Manage tasks on your calendar",
        description: "Learn how to manage your Task events on your calendar during the week.",
        shortDescription: "How to manage your Tasks on your calendar",
        duration: "5min",
        icon: DateRangeOutlinedIcon,
        steps: [
          { id: "GOTO_PLANNER", type: "redirect", redirect: "/planner" },
          {
            id: "MANAGE_TASKS_VIDEO",
            type: "orb",
            popoverConfig: {
              description: "Click below to watch a video on managing your Tasks.",
            },
            videoLink: "fDEZmwcL6nI",
          },
        ],
      },
      {
        id: "SET_TASK_DEFAULTS",
        groupId: "TASKS",
        title: "Set your Task defaults",
        description: "Define your default settings for how Tasks are scheduled via Reclaim.",
        shortDescription: "Set defaults for how Tasks are scheduled",
        duration: "2min",
        icon: CogIcon,
        steps: [
          { id: "GOTO_TASK_SETTINGS", type: "redirect", redirect: "/settings/general#tasks" },
          {
            id: "TASK_DEFAULTS_INFO",
            type: "orb",
            popoverConfig: {
              description:
                "Edit your default task duration, preferred hours, visibility to others, and auto-reopen/close settings.",
            },
          },
        ],
      },
    ],
  },
  /********************/
  /*     CUSTOMIZE    */
  /********************/
  CUSTOMIZE_CALENDAR: {
    id: "CUSTOMIZE_CALENDAR",
    title: "Customize your calendar",
    quests: [
      {
        id: "AUTO_SCHEDULE_BREAK_AND_TRAVEL_TIME",
        groupId: "CUSTOMIZE_CALENDAR",
        title: "Auto-schedule breaks & travel",
        description: "Make time for breaks between meetings to travel & decompress.",
        shortDescription: "Make time for breaks between meetings",
        duration: "2min",
        icon: CoffeeIcon,
        steps: [{ id: "", type: "redirect", redirect: "/settings/general#buffer" }],
      },
      {
        id: "SETUP_NO_MEETING_DAYS",
        groupId: "CUSTOMIZE_CALENDAR",
        title: "Set up No-Meetings Days",
        description: "Use hashtags in your calendar to set no-meetings days & provide special instructions to Reclaim.",
        shortDescription: "Use hashtags to set no-meetings days",
        duration: "2min",
        icon: HashtagIcon,
        steps: [{ id: "", type: "redirect", redirect: "" }],
      },
      {
        id: "CUSTOMIZE_EVENT_COLOR_CODING",
        groupId: "CUSTOMIZE_CALENDAR",
        title: "Customize event color-coding",
        description: "Automatically color-code your events by category to keep your schedule visually organized.",
        shortDescription: "Color-code your events by category",
        duration: "2min",
        icon: ColorLensOutlinedIcon,
        steps: [{ id: "", type: "redirect", redirect: "/settings/colors" }],
      },
      {
        id: "TRACK_AND_ANALYZE_PRODUCTIVITY",
        groupId: "CUSTOMIZE_CALENDAR",
        title: "Track & analyze your productivity",
        description: "Learn how to use Reclaim's calendar stats to keep tabs on your productivity & work-life balance.",
        shortDescription: "Keep tabs on your productivity & work-life balance",
        duration: "2min",
        icon: BarChartRoundedIcon,
        steps: [{ id: "", type: "redirect", redirect: "/stats" }],
      },
    ],
  },
  /********************/
  /*       TEAM       */
  /********************/
  INVITE_TEAM: {
    id: "INVITE_TEAM",
    title: "Invite your team",
    quests: [
      {
        id: "ADD_MEMBERS_TO_TEAM",
        groupId: "INVITE_TEAM",
        title: "Add members to your team",
        description: "Start building out your team to get the most out of Reclaim.",
        shortDescription: "Build your team & get the most out of Reclaim",
        duration: "3min",
        icon: UserAddIcon,
        steps: [{ id: "", type: "redirect", redirect: "/billing#team" }],
      },
      {
        id: "SELECT_PLAN",
        groupId: "INVITE_TEAM",
        title: "Select a plan",
        description: "Find the right Reclaim plan for your team's needs.",
        shortDescription: "Find the right plan for your needs",
        duration: "2min",
        icon: GridIcon,
        steps: [{ id: "", type: "redirect", redirect: "/billing" }],
      },
    ],
  },
};
